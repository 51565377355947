// export const achievementData = {
  // bio: "I am a full stack web developer. I have completed my graduation from the department of Computer Science and Engineering. I have a passion for web development and love to create web applications. I am a quick learner and always eager to learn new technologies. I am a hard-working person and always try to give my best effort to complete any task. I am a team player and love to work with a team. I am a self-motivated person and always try to motivate others.",
  // achievements: [
  //   {
  //     id: 1,
  //     title: "Full Stack Web Development Certification",
  //     details: "I successfully completed full stack web developer bootcamp.",
  //     date: "Sep 20, 2022",
  //     field: "Full Stack ",
  //     image: "https://i.ibb.co/qN1RCm0/Capture.png",
  //   },
  //   {
  //     id: 2,
  //     title: "Front End Libraries Certification",
  //     details:
  //       "I successfully completed front-end web developer bootcamp - 14 projects on 2021-02-21 and certified for passing all exams.",
  //     date: "Jan 29, 2022",
  //     field: "Development",
  //     image: "https://i.ibb.co/Kh7q3GB/Capture.png",
  //   },
  //   {
  //     id: 3,
  //     title: "Responsive Web Design Certification",
  //     details: "In this Responsive Web Design Certification.",
  //     date: "Feb 22, 2021",
  //     field: "Development",
  //     image: "https://i.ibb.co/42zMsYX/Capture.png",
  //   },
  // ],
// };

// Do not remove any fields.
// Leave it blank instead as shown below.



export const achievementData = {
    bio : "",
    achievements : []
}

