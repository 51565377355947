export const experienceData = [
    {
        id: 1,
        company: 'TheWebPatriot',
        jobtitle: 'Full Stack Developer (MERN Stack)',
        startYear: 'Oct 2022',
        endYear: 'Jan 2024'
    },
    {
        id: 2,
        company: 'Masai School',
        jobtitle: 'Full Stack Developer (MERN Stack)',
        startYear: 'Oct 2021',
        endYear: 'Sep 2022'
    },
    
]
