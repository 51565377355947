import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [



    {
        id: 2,
        projectName: 'Socially Shoppable',
        projectDesc: 'This is an affiliate website where users can buy products of different brands.',
        tags: ['RACT','REDUX','MONGODB','JSX','EXPRESS','MONGOOSE'],
        code: '#',
        demo: 'https://ss.demo.thewebpatriot.co.in/',
        image: 'https://i.ibb.co/s9zkvsG/ss.png'
    },
    {
        id: 3,
        projectName: 'Agoda Clone',
        projectDesc: 'Agoda is one of the world fastest growing online travel booking platforms. From its beginnings as an e-commerce start-up based in Singapore in 2005.',
        tags: ['RACT','REDUX','MONGODB','JSX','EXPRESS','MONGOOSE'],
        code: 'https://github.com/shailendra7518/Agoda-clone',
        demo: 'https://agoda-clone.vercel.app/',
        image: 'https://i.ibb.co/5TJzyWQ/AGODA-CLONE.jpg'
    },
    {
        id: 1,
        projectName: 'CAD BULL',
        projectDesc: '2D Cad Library, Cad Blocks, Autocad Blocks Furniture, Architecture-Interiors design-Construction floor plan and detail in autocad dwg format.',
        tags: ['React', 'CSS', 'Material Ui', 'Node Js'],
        code: '',
        demo: 'https://cadbull.com/',
        image: 'https://i.ibb.co/GR7njjC/cadbull.png'
    },
    // {
    //     id: 2,
    //     projectName: 'Railway Tracking',
    //     projectDesc: 'This project is a railway tracking system that allows users to obtain relevant information about train timing.',
    //     tags: ['Django', 'Tailwind CSS'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: two
    // },
    // {
    //     id: 3,
    //     projectName: 'Weather App',
    //     projectDesc: 'Weather forecast systems and applications predict weather conditions based on multiple parameters.',
    //     tags: ['Django', 'CSS', 'Material Ui'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: three
    // },
    // {
    //     id: 4,
    //     projectName: 'Android Patient Tracker',
    //     projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
    //     tags: ['Flutter', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: four
    // },
    // {
    //     id: 5,
    //     projectName: 'E-Commerce App',
    //     projectDesc: 'A Simple E-commerce application',
    //     tags: ['React Native', 'Firebase'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: five
    // },
    // {
    //     id: 6,
    //     projectName: 'Uber Lite',
    //     projectDesc: 'Uber clone',
    //     tags: ['Flutter'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: six
    // },
    // {
    //     id: 7,
    //     projectName: 'Stock Market App',
    //     projectDesc: 'A simple stock market API app',
    //     tags: ['React', 'Redux', 'Bootstrap'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: seven
    // },
    // {
    //     id: 8,
    //     projectName: 'Car Pooling System',
    //     projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    //     tags: ['Flutter', 'React'],
    //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    //     image: eight
    // },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
