export const blogData = [
    {
        id: 1,
        title: 'Hello Reader, Hope you are keeping safe this is my first imagine story_',
        description: 'This is the toy that gives me a kiss and happens. I want to play in the garden. I never want to lose a smaller toy. I want to this is always with me never leave me alone.',
        date: 'Feb 27, 2022',
        image: 'https://i.ibb.co/WsSdXt7/blog1.png',
        url: 'https://medium.com/@webdeveshverma/hello-reader-hope-you-are-keeping-safe-this-is-my-first-imagine-story-151333724c09'
    },
    {
        id: 2,
        title: 'Skills vs Degrees',
        description: 'Degree and skill are two sides of the same coin. To succeed in the race of life, a person needs to have a degree along with the skill.',
        date: 'Feb 27, 2022',
        image: 'https://i.ibb.co/Cbg4qfV/Blog2.png',
        url: 'hhttps://medium.com/@webdeveshverma/skills-vs-degrees-9f24c882f23b'
    },
    {
        id: 3,
        title: 'Clone of Marriott Bonvoy',
        description: 'Firstly as a team, we would like to express how grateful we are to Masai School for giving us this wonderful opportunity to learn under their guidance and for sharing our project experience which we had done remotely.',
        date: 'March 19, 2022',
        image: 'https://i.ibb.co/qjby6Yc/Blog3.png',
        url: 'hhttps://medium.com/@webdeveshverma/skills-vs-degrees-9f24c882f23b'
    },
    {
        id: 4,
        title: 'CHECK INTERNET SPEED FROM THE COMMAND LINE',
        description: 'Want to check your internet speed from the terminal? Here\'s the perfect way to the that.',
        date: 'Sep 17, 2020',
        image: 'https://1.bp.blogspot.com/-XTVMsZP3HaM/Xxpl394txlI/AAAAAAAABws/zovXWiuNGg4TzBwYYVFfJ1ixDb3JfVokgCNcBGAsYHQ/w400-h195/speedtest.png',
        url: 'https://hackzism.blogspot.com/2020/07/check-internet-speed-from-command-line.html'
    },
    {
        id: 5,
        title: 'GET MICHAEL\'S OR TREVOR\'S VEHICLES FULLY UPGRADED IN GTA V FOR FREE',
        description: 'In case you haven\'t heard of this game, GTA stands for \'Grand Theft Auto\'. It is an open-world game published by Rockstar Games.',
        date: 'Oct 1, 2020',
        image: 'https://1.bp.blogspot.com/-jhdtUBIRD_s/XxLFKQuRz9I/AAAAAAAABsM/mClfbfDFKvsUwA7Wyi25Lzu3DEmL2lxrACNcBGAsYHQ/s1919/gta.jpg',
        url: 'https://hackzism.blogspot.com/2020/07/how-can-you-get-michaels-or-trevors.html'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/